export const googleMapsApiKey = 'AIzaSyBbxKlTQcZkGpA2euHKxkS3kGEDaa-QvxE';
export const googleMapsGeocodeApiUri = 'https://maps.googleapis.com/maps/api/geocode/json'
export const googleReCAPTCHAv3SiteKey = '6LfenewkAAAAAKc3vmrCZgEbf6MPwHKmZGxlfGu3'

export const GetMsClarityProjectId = () : string => {
    if(process.env.NODE_ENV === 'production') {
        return 'mwtnwuh2ny';
    }
    return 'mwtqrmsuyd';
}

export const GetGoogleTagId = () : string => {
    if(process.env.NODE_ENV === 'production') {
        return 'G-QW2C32YRX3';
    }
    return 'G-8Q4KBSDMGM';
}