import React, { useEffect } from 'react';
import {GetGoogleTagId} from '../config';
import ReactGA from 'react-ga4';

const GoogleTag: React.FC = () => {

    useEffect(() => {
        const measurementId = GetGoogleTagId();
        if (measurementId) {
            ReactGA.initialize(measurementId)
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname,
                title: document.title,
                location: window.location.href,
            });
        }
    }, []);
    return null;
};

export default GoogleTag;